const social = [
    {
        name: 'LinkedIn',
        url: 'https://linkedin.com/in/rebekahbulych',
        img: 'https://i.imgur.com/ueJsTr3.png',
        alt: 'LinkedIn'
    },
    {
        name: 'Github',
        url: 'https://github.com/panfrieddupa',
        img: 'https://i.imgur.com/0rWFMI8.png',
        alt: 'Github'
    },
    {
        name: 'CodePen',
        url: 'https://codepen.io/panfrieddupa',
        img: 'https://i.imgur.com/dYzAK28.png',
        alt: 'CodePen'
    },
    {
        name: 'DeviantArt',
        url: 'https://deviantart.com/panfrieddupa',
        img: 'https://i.imgur.com/93WNRgE.png',
        alt: 'DeviantArt'
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/panfrieddupa',
        img: 'https://i.imgur.com/bwQsnba.png',
        alt: 'Twitter'
    },
    {
        name: 'Instagram',
        url: 'https://instagram.com/panfrieddupa',
        img: 'https://i.imgur.com/VUrcGEI.png',
        alt: 'Instagram'
    }
]

export default social